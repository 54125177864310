<!-- <app-header></app-header> -->
<!-- <app-footer></app-footer> -->


<div *ngIf="isMobile">
    <h1 class="one">
        <img src="assets/img/logo.png" alt=""><br>
        <div>
            اهلا بكم في بوابة تقديم طلبات التعيين 
            لوظيفة معاون نيابة ادارية ..
            <br>
            <br>
            تسجيل البيانات وتقديم طلبات التعيين يتم فقط 
             من خلال الحاسب الالى ..
        </div>
    </h1>
</div>
<div *ngIf="!isMobile">
    <router-outlet></router-outlet>
</div>