
import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
@Component({
  selector: 'app-print-data',
  templateUrl: './print-data.component.html',
  styleUrls: ['./print-data.component.scss']
})
export class PrintDataComponent implements OnInit {


  constructor(
    private apiService: ApiService
  ) { }
  show: boolean = false;
  paydone: boolean = false;
  ngOnInit(): void {

    this.CandidateResetDataApi();

  }
  CandidateDataMainReset: any;
  CandidateResetDataApi() {
    const formData = new FormData();
    formData.append('UserID', localStorage.getItem('UserID'));
    return this.apiService.CandidateResetDataApi(formData).subscribe(
      (response: any) => {
        console.log(response)
        this.paydone = true;

        this.CandidateDataMainReset = response.CandidateDataMainReset
        // this.UpdateCandidateDataPStatusApi(response);
        // this.goToCheckout(response);
      })
  }

  UpdateCandidateDataPStatusApi() {
    const formData = new FormData();
    formData.append('UserID', localStorage.getItem('UserID'));
    formData.append('OrderID', localStorage.getItem('OrderID'));
    formData.append('SessionID', localStorage.getItem('SessionID'));
    return this.apiService.UpdateCandidateDataPStatusApi(formData).subscribe(
      (response: any) => {
        console.log(response)
        localStorage.setItem('PaymentStatus', response.CandidateData.PaymentStatus)
        localStorage.removeItem('OrderID')
        localStorage.removeItem('SessionID')
        this.CandidateResetDataApi();

        // this.goToCheckout(response);
      })
  }

  printPage() {

    let divName = "divprintid";
    var printContents = document.getElementById(divName).innerHTML;
    var originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;

    window.print();

    document.body.innerHTML = originalContents;

  }

}
