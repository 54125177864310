<div class="container ">
    <div class="row ">
        <div class="col-md-3"></div>
        <div class="col-md-6">
            <div class="pay-done">
                <img src="assets/img/done.png" alt="">
                <h3> طباعة الايصال </h3>

                <div class="d-flex">
                    <button class="btn" (click)="printPage()"> طباعة الايصال </button>
                    <a class="btn" routerLink="/document"> رجوع </a>
                </div>

            </div>
            <br>
            <br>
            <br>
            <br>
        </div>
    </div>

    <div class="row d-none" id="divprintid" *ngIf="CandidateDataMainReset">
        <div class="col-md-12">
            <div class="topHead">
                <div class="container">
                    <div class="row">
                        <div class="col-md-4 col-sm-6  col-6">
                            <div class="logo">
                                <img src="assets/img/logo.png" alt="">
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 text-center ">
                        </div>
                        <div class="col-md-4 col-sm-6  col-6 text-end">
                            <h5> جمهوريـــة مصـــر العربيـــة </h5>
                            <h5> المجلس الأعلى للنيابة الإدارية </h5>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <div class="watrerMark">
                <img src="assets/img/mark.jpg" alt="">
            </div>
        </div>
        <div class="col-md-12" *ngIf="CandidateDataMainReset">
            <div style="background: #fff;padding: 100px 50px 200px 50px ;">
                <div>
                    <h5 style="font-size: 24px; color: #000; font-weight: 700;">
                        الرقم المرجعي: {{CandidateDataMainReset.OrderID}}
                    </h5>
                </div>
                <div style="text-align: center;">
                    <h4 style="font-size: 26px;font-weight: 700; padding:10px;">إيصال دفع</h4>
                    <h4 style="font-size: 26px;font-weight: 700;"> نسخــــة الـمـــتـــقدم
                        <br> (1)
                    </h4>
                    <br>
                </div>
                <div style="display: flex;">
                    <h6 style="width: 50%; font-size: 24px; font-weight: 600; color: #000; padding:30px 15px;">
                        الاسم رباعي :{{CandidateDataMainReset.ARName}}
                    </h6>
                    <h6 style="width: 50%; font-size: 24px; font-weight: 600; color: #000; padding:30px 15px;">
                        الرقم القومى:{{CandidateDataMainReset.NationalID}}
                    </h6>
                </div>
                <div style="display: flex;">
                    <h6 style="width: 33%; font-size: 22px; font-weight: 600; color: #000; padding:30px 15px;">
                        الجامعة :{{CandidateDataMainReset.University}}
                    </h6>
                    <h6 style="width: 33%; font-size: 22px; font-weight: 600; color: #000; padding:30px 15px;">
                        الشعبة:{{CandidateDataMainReset.UniversityDepartment}}
                    </h6>
                    <!-- <h6 style="width: 33%; font-size: 22px; font-weight: 600; color: #000; padding:30px 15px;">
                        التقدير:{{CandidateDataMainReset.TotalGrade}}..
                    </h6> -->
                </div>
            </div>
        </div>
        <div>
            <hr class="line">
        </div>
        <div class="col-md-12" *ngIf="CandidateDataMainReset">
            <div style="background: #fff;padding:100px  50px;">
                <div>
                    <h5 style="font-size: 22px; color: #000; font-weight: 700;">
                        الرقم المرجعي: {{CandidateDataMainReset.OrderID}}
                    </h5>
                </div>
                <div style="text-align: center;">
                    <h4 style="font-size: 26px;font-weight: 700;padding:10px;">إيصال دفع</h4>
                    <h4 style="font-size: 26px;font-weight: 700;"> نسخــــة سحــــب الملــــف
                        <br>
                        (1)
                    </h4>
                    <br>
                </div>
                <div style="display: flex;">
                    <h6 style="width: 50%; font-size: 22px; font-weight: 600; color: #000; padding:30px 15px;">
                        الاسم رباعي : {{CandidateDataMainReset.ARName}}
                    </h6>
                    <h6 style="width: 50%; font-size: 22px; font-weight: 600; color: #000; padding:30px 15px;">
                        الرقم القومى: {{CandidateDataMainReset.NationalID}}
                    </h6>
                </div>
                <div style="display: flex;">
                    <h6 style="width: 33%; font-size: 22px; font-weight: 600; color: #000; padding:30px 15px;">
                        الجامعة :{{CandidateDataMainReset.University}}
                    </h6>
                    <h6 style="width: 33%; font-size: 22px; font-weight: 600; color: #000; padding:30px 15px;">
                        الشعبة:{{CandidateDataMainReset.UniversityDepartment}}
                    </h6>
                    <!-- <h6 style="width: 33%; font-size: 22px; font-weight: 600; color: #000; padding:30px 15px;">
                        التقدير:{{CandidateDataMainReset.TotalGrade}} 
                    </h6> -->
                </div>
            </div>
        </div>
    </div>
</div>










<!-- <div style="background: #fff;text-align: center;padding: 15px;" *ngIf="!show">
    <h5 style="font-size: 22px;  font-weight: 600; border-bottom: 3px solid #666; padding: 0px 0 15px;">
        الدفع </h5>
    <div style="text-align: start;padding: 15px;">
        <p style="font-size: 16px;font-weight: 700;color: #000;"> الاسم : محمد على سيد حسن </p>
        <p style="font-size: 16px;font-weight: 700;color: #000;"> الرقم القومى : 123578454523513 </p>
    </div>
    <div style="padding: 10px;">
        <p style="font-size: 16px;font-weight: 700;color: #000;">تم استلام مبلغ </p>
        <h3 style="font-size: 24px; font-weight: 700;padding: 16px;"> 506 جنيها </h3>
        <p style="font-size: 16px;font-weight: 700;color: #000;"> فقط خمسمائة وستة جنيها لا غير </p>
    </div>
    <div style="border-top: 3px solid #555;padding: 15px 0 0;margin: 20px 0 0;">
        <h5 style="font-size: 18px;font-weight: 700;"> جمهوريـــة مصـــر العربيـــة </h5>
        <h5 style="font-size: 18px;font-weight: 700;"> المجلس الأعلى للنيابة الإدارية </h5>
        <h5 style="font-size: 18px;font-weight: 700;"> الأمانــــة العامــــة </h5>
    </div>
</div> -->