import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  isMobile: boolean;
  constructor(
  ) { }

  ngOnInit() {

    // return ( ( window.innerWidth <= 800 ) && ( window.innerHeight <= 600 ) );

    if (window.innerWidth < 768) {
      this.isMobile = false;
    }
    else {
      this.isMobile = false;
    }
    console.log(window.innerWidth)
    console.log(window.innerWidth < 768)
  }



}
