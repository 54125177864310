import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { PrintDataComponent } from './print-data/print-data.component';

const routes: Routes = [
  { path: '', redirectTo: '/document', pathMatch: 'full' },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'print-data', component: PrintDataComponent },
  { path: 'document', loadChildren: () => import('./document/document.module').then(m => m.DocumentModule) },
  { path: 'registerold', loadChildren: () => import('./register/register.module').then(m => m.RegisterModule) },
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  { path: 'print', loadChildren: () => import('./print/print.module').then(m => m.PrintModule) },
  { path: 'register', loadChildren: () => import('./payment/payment.module').then(m => m.PaymentModule) },
  { path: 'payment-done', loadChildren: () => import('./pay-done/pay-done.module').then(m => m.PayDoneModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
