
import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  items = ['1', '2', '3']
  constructor(
    private title: Title, private meta: Meta
  ) { }

  ngOnInit() {
    this.title.setTitle(' بوابة تقديم طلبات التعيين لوظيفة معاون نيابة ادارية - هيئة النيابة الادارية');
    // this.meta.addTags([
    //   {name: 'keywords', content: ''},
    //   {name: 'description', content: ''},
    //   {name: 'robots', content: 'index, follow'}
    // ]);



  }
}
