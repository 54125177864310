import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  // url = 'http://apsys.westeurope.cloudapp.azure.com';
  // url = 'http://apsys.cloudapp.net';
  // url = 'https://localhost:44336/';

  // FromProductionToTest
  // url = 'https://api2020test.apsys.app';
  // url = 'https://test.apsys.app';

   url = 'https://api2022.apsys.app';
    //  url = 'https://adminapi.apsys.app';
   // url = '  https://localhost:44336/';

    // url = 'https://altapi.apsys.app';
    //  url = 'https://alt2022api.apsys.app';



  constructor(private http: HttpClient) { }

  Register(body) {
    return this.http.post(`${this.url}/account/RegisterApi`, body);
  }
  Login(body) {
    return this.http.post(`${this.url}/account/CandidateLoginApi`, body);
  }

  //=============================================
  RegisterWithPaymentApi(body) {
    return this.http.post(`${this.url}/account/RegisterWithPaymentApi`, body);
  }
  CandidateWaitingForPayment(body) {
    return this.http.post(`${this.url}/account/CandidateWaitingForPayment`, body);
  }
  UpdateCandidateDataPStatusApi(body) {
    return this.http.post(`${this.url}/account/UpdateCandidateDataPStatusApi`, body);
  }
  CandidateResetDataApi(body) {
    return this.http.post(`${this.url}/account/CandidateResetDataApi`, body);
  }
  CandidateForgetPasswordApi(body) {
    return this.http.post(`${this.url}/account/CandidateForgetPasswordApi`, body);
  }
  CandidateDataSessionApi(body) {
    
    return this.http.post(`${this.url}/account/CandidateDataSessionApi`, body);
  }
  

  //=============================================
  UpdateCandidateDataMainAsync(body) {
    return this.http.post(`${this.url}/Resources/UpdateCandidateDataMainAsync`, body);
  }
  //=============================================
  UpdateCandidateDataGeneralActivityAsync(body) {
    return this.http.post(`${this.url}/Resources/UpdateCandidateDataGeneralActivityAsync`, body);
  }
  //=============================================
  UpdateCandidateDataNotesAsync(body) {
    return this.http.post(`${this.url}/Resources/UpdateCandidateDataNotesAsync`, body);
  }
  //=============================================
  UpdateCandidateRelativeDataFatherAsync(body) {
    return this.http.post(`${this.url}/Resources/UpdateCandidateRelativeDataFatherAsync`, body);
  }
  //=============================================
  UpdateCandidateRelativeDataMotherAsync(body) {
    return this.http.post(`${this.url}/Resources/UpdateCandidateRelativeDataMotherAsync`, body);
  }
  //=============================================
  UpdateCandidateRelativeDataGrandfatherAsync(body) {
    return this.http.post(`${this.url}/Resources/UpdateCandidateRelativeDataGrandfatherAsync`, body);
  }
  //=============================================
  UpdateCandidateRelativeDataMotherfatherAsync(body) {
    return this.http.post(`${this.url}/Resources/UpdateCandidateRelativeDataMotherfatherAsync`, body);
  }
  //=============================================
  UpdateCandidateRelativeDatasync(body) {
    return this.http.post(`${this.url}/Resources/UpdateCandidateRelativeDatasync`, body);
  }
  //=============================================
  GetCandidateRelativeDatasync(body) {
    return this.http.post(`${this.url}/Resources/GetCandidateRelativeDatasync`, body);
  }
  //=============================================
  UpdateCandidateRelativeDataSonssync(body) {
    return this.http.post(`${this.url}/Resources/UpdateCandidateRelativeDataSonssync`, body);
  }
  //=============================================
  UpdateCandidateDataUploadsAsync(body) {
    return this.http.post(`${this.url}/Resources/UpdateCandidateDataUploadsAsync`, body);
  }


  //=============================================

  GetAllCandidateDataMain(id) {
    return this.http.get(`${this.url}/Resources/GetAllCandidateDataMain/${id}`);
  }
  GetAllCandidateDataGeneralActivity(id) {
    return this.http.get(`${this.url}/Resources/GetAllCandidateDataGeneralActivity/${id}`);
  }
  GetAllCandidateDataNotes(id) {
    return this.http.get(`${this.url}/Resources/GetAllCandidateDataNotes/${id}`);
  }

  GetAllCandidateRelativeDataFather(id) {
    return this.http.get(`${this.url}/Resources/GetAllCandidateRelativeDataFather/${id}`);
  }
  GetAllCandidateRelativeDataMother(id) {
    return this.http.get(`${this.url}/Resources/GetAllCandidateRelativeDataMother/${id}`);
  }
  GetAllCandidateRelativeDataGrandfather(id) {
    return this.http.get(`${this.url}/Resources/GetAllCandidateRelativeDataGrandfather/${id}`);
  }
  GetAllCandidateRelativeDataMotherfather(id) {
    return this.http.get(`${this.url}/Resources/GetAllCandidateRelativeDataMotherfather/${id}`);
  }
  GetAllCandidateRelativeDataSons(id) {
    return this.http.get(`${this.url}/Resources/GetAllCandidateRelativeDataSons/${id}`);
  }
  GetAllCandidateDataUploads(id) {
    return this.http.get(`${this.url}/Resources/GetAllCandidateDataUploads/${id}`);
  }




  //=============================================
  GetAllYears() {
    return this.http.get(`${this.url}/Resources/GetAllYears`);
  }
  // YearOfGraduationID	int سنه التخرج  From table Year
  //=============================================
  GetAllReligon() {
    return this.http.get(`${this.url}/Resources/GetAllReligon`);
  }
  //   ReligionID	int الديانه  from table Religon
  //=============================================
  GetAllNationality() {
    return this.http.get(`${this.url}/Resources/GetAllNationality`);
  }
  // NationalityID	int الجنسية  from table Nationality

  //=============================================
  GetAllSocialStatus() {
    return this.http.get(`${this.url}/Resources/GetAllSocialStatus`);
  }
  // SocialStatusID	int الحاله الاجتماعيه   from table SocialStatus
  //=============================================
  GetAllCertificate() {
    return this.http.get(`${this.url}/Resources/GetAllCertificate`);
    // CertificateID	int المؤهل الدراسي  From Table Certificate
  }
  //=============================================
  GetAllUniversity() {
    return this.http.get(`${this.url}/Resources/GetAllUniversity`);
  }
  //=============================================
  GetAllUniversityDepartment() {
    return this.http.get(`${this.url}/Resources/GetAllUniversityDepartment`);
  }
  // UniversityID	int الجامعه From Table University
  //=============================================
  GetAllGradeTurn() {
    return this.http.get(`${this.url}/Resources/GetAllGradeTurn`);
  }
  // GradeTurnID	int الدور  From Table GradeTurn
  //=============================================
  GetAllGovernerateWorking() {
    return this.http.get(`${this.url}/GovernerateWorking/GetAllGovernerateWorking`);
  }
  // GovernerateID	int المحافظة  from Table Governerate
  //=============================================

  GetAllPoliceStation() {
    return this.http.get(`${this.url}/PoliceStation/GetAllPoliceStation`);
  }
  // PoliceStationID	int القسم    PoliceStation
  // To get all plice stations
  //=============================================
  GetAllPoliceStations() {
    return this.http.get(`${this.url}/PoliceStation/GetAllPoliceStations/GovID`);
  }
  // To get all أدخل مركز/ قسم شرطة المقيم فيه حاليا *
  //=============================================
  GetAllCandidateResidenceType() {
    return this.http.get(`${this.url}/Resources/GetAllCandidateResidenceType`);
  }
  // ResidenceTypeID	int  نوع السكن  CandidateResidenceType
  //=============================================
  GetAllGrade() {
    return this.http.get(`${this.url}/Resources/GetAllGrade`);
  }
  // ResidenceTypeID	int  نوع السكن  CandidateResidenceType
  //=============================================
  //=============================================
  GetAllCandidateRelativeType() {
    return this.http.get(`${this.url}/Resources/GetAllCandidateRelativeType`);
  }
  // CandidateRelativeTypeID  درجه القرابه   from CandidateRelativeType
  //=============================================
  PrintCandidateDatasync(post) {
    return this.http.post(`${this.url}/Resources/PrintCandidateData`, post);
  }
  PrintCandidateData(id) {
    return this.http.get(`${this.url}/Resources/PrintCandidateData/${id}`);
  }

  //=============================================
  UpdateCandidateDataPrintedAsync(post) {
    return this.http.post(`${this.url}/Resources/UpdateCandidateDataPrintedAsync`, post);
  }
  //=============================================


}
